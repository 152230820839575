import React, { ChangeEvent, FC, MouseEventHandler, useEffect, useState } from 'react';
import classNames from 'classnames';
import { AssessmentData } from '../../types/assessment';

type AssessmentStatusProps = {
    assessment: AssessmentData;
};

export const AssessmentStatus: FC<AssessmentStatusProps> = ({ assessment }) => {
    var text = 'Необходимо оплатить';
    var cls = 'grey';

    if (assessment.is_finished) {
        text = 'Завершен';
        cls = 'green';
    } else if (assessment.is_paid) {
        text = 'Активен';
        cls = 'blue';
    }

    return (
        <div className={'tag ' + cls}>{text}</div>
    );
};
import classNames from 'classnames';
import React, { FC, useEffect, useState } from 'react';

import { useLocation } from 'react-router';
import { Outlet } from 'react-router-dom';
import { Header } from '../components/Header';
import { HeaderUserSurvey } from '../components/HeaderUserSurvey';
import { Notice, Notices } from '../components/Notices/Notices';
import styles from './Layout.module.scss';

type LayoutProps = {
  //children: React.ReactNode;
};

export const Layout: FC<LayoutProps> = ({  }) => {
  const { pathname } = useLocation();
  //const [notices, setNotices] = useState<Notice[]>([]);

  /*useEffect(() => {
    window.addEventListener('rdc_notice', (e) => {
        setNotices((prev) => ([...prev, { type: (e as any).noticeType, message: (e as any).noticeMessage }]));
    });
  }, []);*/

  useEffect(() => {
    document.querySelector('html')?.setAttribute('translate', 'no');
  }, []);

  return (
    <>
      {pathname.indexOf('/rdc-admin') != -1
      || pathname.indexOf('/signup') != -1 ? (
        <>
          <div className={styles.orange_bg}><Outlet /></div>
          <Notices />
        </>
        ) : (
          <>
            {pathname.indexOf('/user_survey') == -1 ? (<Header />) : (<HeaderUserSurvey />)}
            
            <main className={classNames(styles.main, {[styles.scrollable]: false})}><Outlet /></main>

            <Notices />
          </>
        )}
    </>
  );
};

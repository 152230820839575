import { FC, ReactElement, ReactNode, useState } from 'react';
import styles from './Form.module.scss';
import { Button } from '../../components/Button';
import classNames from 'classnames';
import { TextInput } from '../TextInput';
import { uuid } from '../../services/utils';
import { SelectInput } from '../SelectInput';
import { DateInput } from '../DateInput';
import { Checkbox } from '../Checkbox';

export type FormElement = {
    type: string;
    label: string;
    isRequired?: boolean;
    dataProperty?: string;
    validate?: (value:any) => string | undefined;
    onClick?: () => void;
    placeholder?: string;
    selectOptions?: any[];
    children?: FormElement[];
};

export type FormColumn = {
    elements?: FormElement[]
};

export type FormStructure = {
    header?: FormColumn;
    footer?: FormColumn;
    body?: FormColumn[];
};

export type FormProps = {
    onSubmit?: (data:any) => void;
    structure: FormStructure;
    formData: any;
};



export const Form: FC<FormProps> = ({ onSubmit, structure, formData }) => {

    const [data, setData] = useState<any>(formData);
    const [showValidationErrors, setShowValidationErrors] = useState<boolean>(false);

    const [fid, setFid] = useState<string>(uuid());
    
    const validateFormElem = (elem: FormElement) => {
        
        
        if (elem.validate) {
            var val = data[elem.dataProperty ?? ''];
            const r = elem.validate(val);
            return r == undefined || r  == '';
        }
        return true;
    }

    const validateForm = () => {
        var res = true;
        structure.header?.elements?.forEach(e => { res = res && validateFormElem(e); })
        structure.footer?.elements?.forEach(e => { res = res && validateFormElem(e); })
        structure.body?.forEach(col => {
            col.elements?.forEach(e => { res = res && validateFormElem(e); e.children?.forEach(child => { res = res && validateFormElem(child); }) })
        });
        return res;
    }
    
    const submitForm = () => {
        setShowValidationErrors(true);

        if (validateForm()) {
            if (onSubmit) onSubmit(data);
        }
    }

    const renderFormElem = (prefix: string, elem: FormElement, index: number) => {
        return <div key={fid + '-' + prefix + 'elem-' + index} className={classNames(styles.form_element, styles[elem.type])}>
            {elem.type == 'text' && (<TextInput label={elem.label} placeholder={elem.placeholder} isRequired={elem.isRequired} value={data[elem.dataProperty ?? '']} showValidationError={showValidationErrors} validate={elem.validate} onChange={(e) => { setData((prev:any) => ({...prev, [elem.dataProperty ?? '']: e.target.value})); }} />)}
            {elem.type == 'date' && (<DateInput label={elem.label} placeholder={elem.placeholder} isRequired={elem.isRequired} value={data[elem.dataProperty ?? '']} showValidationError={showValidationErrors} validate={elem.validate} onChange={(date, e) => { setData((prev:any) => ({...prev, [elem.dataProperty ?? '']: date})); }} />)}
            {elem.type == 'select' && (
                <SelectInput label={elem.label} placeholder={elem.placeholder} isRequired={elem.isRequired} value={elem.selectOptions?.find(o => o.value == data[elem.dataProperty ?? ''])} showValidationError={showValidationErrors} validate={elem.validate}
                    options={elem.selectOptions ?? []} onChange={(newValue, actionMeta) => { setData((prev:any) => ({...prev, [elem.dataProperty ?? '']: newValue.value })) }}
                 />
            )}
            {elem.type == 'checkbox' && (
                <Checkbox label={elem.label} defaultChecked={data[elem.dataProperty ?? '']} onChange={(e) => { setData((prev:any) => ({...prev, [elem.dataProperty ?? '']: (e.target as any).checked})); }} />
            )}
            {elem.type == 'flex' && (
                elem.children?.map((child:FormElement, ind) => renderFormElem(prefix, child, index + 1000 + ind))
            )}
            {elem.type == 'submit' && (<Button label={elem.label} type='general' onClick={submitForm} />)}
            {elem.type == 'button' && (<Button label={elem.label} type='empty' onClick={elem.onClick} />)}
        </div>
    }

    return (
    <div className={styles.form}>
        {structure.header && structure.header.elements && (
            <div className={styles.form_header}>
                {structure.header.elements.map((el, i) => renderFormElem('h-', el, i))}
            </div>
        )}

        {structure.body && (
            <div className={styles.form_body}>
                {structure.body.map((col, index) => <div key={fid + '-fcol-' + index} className={styles.form_col}>{col.elements?.map((el, i) => renderFormElem('b-', el, i))}</div>)}
            </div>
        )}

        {structure.footer && structure.footer.elements && (
            <div className={styles.form_footer}>
                {structure.footer.elements.map((el, i) => renderFormElem('f-', el, i))}
            </div>
        )}
    </div>);
};
import React, { useEffect, useState } from 'react';
import styles from './Signup.module.scss';
import { TextInput } from '../../components/TextInput';
import { Button } from '../../components/Button';
import { ReactComponent as ConfirmIcon } from '../../assets/img/signup-confirm.svg';
import { useSearchParams } from 'react-router-dom';
import { SelectInput } from '../../components/SelectInput';
import { getUserInfo, handleHttpError } from '../../services/api';
import { AccountSignupData } from '../../types/account';
import { signupAccount } from '../../services/account';
import { validateRequired } from '../../services/utils';

export function Signup() {
    require('../../assets/css/login.css');

    const [queryParams, setQueryParams] = useSearchParams();
    const [signupData, setSignupData] = useState<AccountSignupData>({});
    const [showValidationErrors, setShowValidationErrors] = useState<boolean>(false);
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);

    useEffect(() => {
        getUserInfo().then(user => {
            if (user)
                window.location.href = '/assessments/';
        });
    }, []);

    const validate = (data:AccountSignupData):boolean => {
        var r = true;

        r = r && (data.fio ?? '') != '';
        r = r && (data.email ?? '') != '';
        r = r && (data.inn ?? '') != '';
        r = r && (data.kpp ?? '') != '';
        r = r && (data.company ?? '') != '';
        r = r && (data.number_of_employees ?? '') != '';

        return r;
    }

    const doSignup = () => {
        setShowValidationErrors(true);

        if (validate(signupData)) {
            signupAccount(signupData).then(res => {
                if (res.id)
                    setShowConfirmation(true);
            }).catch(handleHttpError);
        }
    }

    return (
        <div className='loginForm signup'>
            {!showConfirmation ? (
                <>
                    <div className='left'>
                        <div className='inner'>
                            <div className='title'>БССГ</div>
                            <div className='subtitle top'>Для работы с опросами<br/>добавьте необходимую<br/>информацию</div>
                        </div>
                    </div>
                    <div className='right'>
                        <div className='inner'>
                            <div className='section-title'>Ваши данные</div>
                            <div className='data'>
                                <TextInput label='ФИО' isRequired showValidationError={showValidationErrors} validate={validateRequired} value={signupData.fio} onChange={(e) => { setSignupData((prev:AccountSignupData) => ({...prev, fio: e.target.value })) }} />
                                <TextInput label='Корпоративная электронная почта' isRequired showValidationError={showValidationErrors} validate={validateRequired} value={signupData.email} onChange={(e) => { setSignupData((prev:AccountSignupData) => ({...prev, email: e.target.value })) }} />
                            </div>
                            <div className='section-title'>Организация</div>
                            <div className='data'>
                                <div className='cols-2'>
                                    <TextInput label='ИНН' isRequired showValidationError={showValidationErrors} validate={validateRequired} value={signupData.inn} onChange={(e) => { setSignupData((prev:AccountSignupData) => ({...prev, inn: e.target.value })) }} />
                                    <TextInput label='КПП' isRequired showValidationError={showValidationErrors} validate={validateRequired} value={signupData.kpp} onChange={(e) => { setSignupData((prev:AccountSignupData) => ({...prev, kpp: e.target.value })) }} />
                                </div>
                                <TextInput label='Название' isRequired showValidationError={showValidationErrors} validate={validateRequired} value={signupData.company} onChange={(e) => { setSignupData((prev:AccountSignupData) => ({...prev, company: e.target.value })) }} />
                                <SelectInput label='Примерная численность сотрудников' isRequired showValidationError={showValidationErrors} validate={validateRequired} placeholder='Выберите из списка' 
                                    options={[ { value: 10, label: '10' }, { value: 100, label: '100' }, { value: 500, label: '500' }, { value: 1000, label: '1000' } ]} 
                                    value={signupData.number_of_employees} onChange={(newValue, actionMeta) => { setSignupData((prev:AccountSignupData) => ({...prev, number_of_employees: newValue.value })) }}
                                />
                            </div>
                            <div className='bottom'>
                                <Button label='Продолжить' type='login' onClick={doSignup}></Button>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className='maintitle'>БССГ</div>
                    <div className='message'>
                        
                        
                        <ConfirmIcon />
                        <div className='pri'>Мы отправили подтверждение<br/>на petrakovsa@gazprom.media</div>
                        <div className='sec'>Перейдите по ссылке, указанной в письме,<br/>чтобы начать работу</div>
                    </div>
                </>
            )}
        </div>
    );
}
import React, { useEffect, useState } from 'react';
import styles from './ErrorPage.module.scss';
import { ReactComponent as BigInfoIcon } from '../../assets/img/info-orange-2.svg';

export function ErrorPage() {
    return <div className={styles.bigmsg}>
    <BigInfoIcon />
    <h3>Ошибка 404:<br/>страницы с таким адресом нет</h3>
    <p>Извините, но страница, которую вы ищете, не найдена</p>
</div>
}
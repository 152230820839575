import { UserData, UserSurveyData } from "../types/user";
import { fetchApi, handleHttpError, handleHttpResponse, requestGet, requestPost, requestPostFile } from "./api";
import Moment from 'moment';


export const createUserSurvey = async (data: UserSurveyData) => {
    return await fetchApi('/v1/user_survey/create', requestPost(data)).then(handleHttpResponse);
}

export const getUserSurvey = async (assessment_id: number, survey_id: string, user_id: string) => {
    return await fetchApi(`/v1/pub/user_survey/get_survey`, requestPost({ assessment_id: assessment_id, survey_id: survey_id, user_id: user_id })).then(handleHttpResponse);
}

export const updateUserSurvey = async (data: UserSurveyData) => {
    return await fetchApi('/v1/user_survey/update', requestPost(data)).then(handleHttpResponse);
}

export const completeUserSurvey = async (assessment_id: number, survey_id: string, user_id: string) => {
    return await fetchApi('/v1/pub/user_survey/complete_survey', requestPost({ assessment_id: assessment_id, survey_id: survey_id, user_id: user_id })).then(handleHttpResponse);
}

export const createUser = async (data: UserData, assessmentId: number) => {
    return await fetchApi(`/v1/user/create?assessmentId=${assessmentId}`, requestPost(data)).then(handleHttpResponse);
}

export const updateUser = async (data: UserData) => {
    return await fetchApi('/v1/user/update', requestPost(data)).then(handleHttpResponse);
}

export const listUserSurveyStatuses = async () => {
    return await fetchApi(`/v1/user_survey/list_status`, requestGet()).then(handleHttpResponse);
}

export const deleteUserSurveys = async (ids: string[]) => {
    return await fetchApi(`/v1/user_survey/delete?ids=${ids.join(',')}`, requestGet()).then(handleHttpResponse);
}

export const blockUserSurveys = async (ids: string[]) => {
    return await fetchApi(`/v1/user_survey/block?ids=${ids.join(',')}`, requestGet()).then(handleHttpResponse);
}

export const unblockUserSurveys = async (ids: string[]) => {
    return await fetchApi(`/v1/user_survey/unblock?ids=${ids.join(',')}`, requestGet()).then(handleHttpResponse);
}

export const getUser = async (id: string) => {
    return await fetchApi(`/v1/pub/user/${id}`, requestGet()).then(handleHttpResponse);
}

export const uploadUsers = async (assessment_id: number, f: File, handleError: (type:string, msg:string) => void) => {
    return await fetchApi(`/v1/assessment/${assessment_id}/upload_users`, requestPostFile(f)).then(resp => handleHttpResponse(resp, handleError));
}

export const downloadUsersTpl = async () => {
    return await fetchApi(`/v1/assessment/download_users_tpl`, requestGet()).then(resp => {
        resp.text().then(res => {
            var data = new Blob([res], { type: 'text/csv' });
            var url = window.URL.createObjectURL(data);
            var tempLink = document.createElement('a');
            tempLink.href = url;
            tempLink.setAttribute('download', 'Форма_респонденты_анкет_v10.csv');
            tempLink.click();
        })
    }).catch(handleHttpError);
}

export const downloadUsers = async (assessment_id: number) => {
    return await fetchApi(`/v1/assessment/${assessment_id}/download_users`, requestGet()).then(resp => {
        resp.text().then(res => {
            var data = new Blob([res], { type: 'text/csv' });
            var url = window.URL.createObjectURL(data);
            var tempLink = document.createElement('a');
            var tm = Moment().format('YYYY-MM-DD_HH:mm:ss')
            tempLink.href = url;
            tempLink.setAttribute('download', `Респонденты_анкет_${assessment_id}_${tm}.csv`);
            tempLink.click();
        })
    }).catch(handleHttpError);
}
import React, { useEffect, useState } from 'react';
import styles from './Assessment.module.scss';
import { ReactComponent as PlusIcon } from '../../assets/img/plus-in-circle.svg';
import { Button } from '../../components/Button';
import { emitEvent } from '../../services/utils';
import { getUserInfo, handleHttpError } from '../../services/api';
import classNames from 'classnames';
import { AssessmentData } from '../../types/assessment';
import { listAssessments } from '../../services/assessment';
import Moment from 'moment';
import { RateData } from '../../types/rate';
import { listRates } from '../../services/rate';
import { AssessmentStatus } from '../../components/AssessmentStatus';
import { AssessmentActions } from '../../components/AssessmentActions';

export function AssessmentList() {
    require('../../assets/css/page.css');

    const [assessments, setAssessments] = useState<AssessmentData[]|undefined>(undefined);
    const [rates, setRates] = useState<RateData[]|undefined>(undefined);

    

    const loadAssessments = () => {
        listAssessments(0, 100).then(res => {
            setAssessments(res);
        }).catch(handleHttpError);
    }

    useEffect(() => {
        getUserInfo().then(user => {
            if (!user)
                window.location.href = '/rdc-admin';
            else {
                loadAssessments();

                window.addEventListener('assessment_created', function(e) {
                    loadAssessments();
                });
            }
        }).catch(handleHttpError);

        listRates(0, 1000).then(res => {
            setRates(res);
        }).catch(handleHttpError);
    }, []);

    

    return (
        <div className={classNames('page transparent', { exp: assessments && assessments.length == 0 })}>
            <div className={classNames('pagecontent', {'nodata': assessments && assessments.length == 0})}>
                {assessments && assessments.length == 0 && (
                    <div className='nodata-msg'>
                        <PlusIcon />
                        <p>Вы пока не создали ни один<br/>опрос</p>
                        <Button label='Создать опрос' onClick={() => { emitEvent('create_assessment', {}) }}></Button>
                    </div>
                )}
                {assessments && assessments.length > 0 && (
                    <div className={styles.list}>
                        {assessments.map(item => <div key={'assessment-' + item.id} className={styles.assessment_item}>
                            <AssessmentActions assessment={item} className={styles.btn_actions} onActionDone={loadAssessments} />
                            <a href={`/assessment/${item.id}`}><h2>{item.name}</h2></a>
                            <div className={styles.info}>
                                <AssessmentStatus assessment={item} />
                                <div className='tag orange'>Тариф <span className='orangetext'>{rates?.find(r => r.id == item.rate_id)?.name}</span></div>
                                <div className='info-date'>{Moment(item.start).format('DD.MM.YYYY')}</div>
                                {item.last_editor && (
                                    <div className='info-person'>{item.last_editor}</div>
                                )}
                            </div>
                            <div className={styles.info_btm}>
                                <div className={styles.info1}>
                                    <h3>Анкетирование</h3>
                                    <div className={styles.items}>
                                        <div className={styles.item}>Всего <span className={styles.bold}>{item.total_user_surveys} / {rates?.find(r => r.id == item.rate_id)?.max_user}</span></div>
                                        <div className={styles.item}>Открыто <span className={styles.bold}>{item.total_user_surveys_active}</span></div>
                                        <div className={styles.item}>Завершено <span className={styles.bold}>{item.total_user_surveys_completed}</span></div>
                                        <div className={styles.item}>Релевантных анкет <span className={styles.bold}>0</span></div>
                                    </div>
                                </div>
                                {(rates?.find(r => r.id == item.rate_id)?.max_user_interviewee ?? 0) > 0 && (
                                <div className={styles.info2}>
                                    <h3>Интервью</h3>
                                    <div className={styles.items}>
                                        <div className={styles.item}>Всего <span className={styles.bold}>{item.total_user_interview_surveys} / {rates?.find(r => r.id == item.rate_id)?.max_user_interviewee}</span></div>
                                        <div className={styles.item}>Назначено встреч <span className={styles.bold}>{item.total_interviews_assigned}</span></div>
                                        <div className={styles.item}>Проведено <span className={styles.bold}>{item.total_interviews_completed}</span></div>
                                    </div>
                                </div>
                                )}
                            </div>
                        </div>)}
                    </div>
                )}
                
            </div>
        </div>
    )
}
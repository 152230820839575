import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import styles from './Company.module.scss';
import { getUserCompany, updateUserCompany } from '../../services/company';
import { handleHttpError } from '../../services/api';
import { CompanyData } from '../../types/company';
import { Button } from '../../components/Button';
import { ModalDlg } from '../../components/ModalDlg';
import { Form } from '../../components/Form';
import { validateRequired } from '../../services/utils';
import { raiseNotice } from '../../components/Notices/Notices';

export function Company() {
    require('../../assets/css/page.css');

    const [data, setData] = useState<CompanyData>({});
    const [editData, setEditData] = useState<any>({});
    const [showEditDlg, setShowEditDlg] = useState<boolean>(false);

    const loadCompany = () => {
        getUserCompany().then(res => {
            setData(res);
            setEditData({ id: res.id, name: res.name, inn: res.details ? res.details[0].inn : '', kpp: res.details ? res.details[0].kpp : '', number_of_employees: res.number_of_employees});
        }).catch(handleHttpError);
    }

    useEffect(() => {
        loadCompany();
    }, []);

    const submitEditCompany = (formData: any) => {
        updateUserCompany(formData).then(res => {
            if (res.id) {
                loadCompany();
                setShowEditDlg(false);
                raiseNotice('info', 'Данные компании сохранены');
            }
        }).catch(handleHttpError);
    }

    return (
        <div className='page transparent'>
            <div className='pagecontent'>
                <div className={styles.list}>
                    <div className={styles.company_item}>
                        <h2>{data.name}</h2>
                        <Button label='Редактировать' type='empty' onClick={() => setShowEditDlg(true)} />
                        <div className={styles.info}>
                            <div className={styles.items}>
                                {data.details && data.details.length > 0 && (
                                    <>
                                        <div className={styles.item}>ИНН <span className='bold'>{data.details[0].inn}</span></div>
                                        <div className={styles.item}>КПП <span className='bold'>{data.details[0].kpp}</span></div>
                                    </>
                                )}
                                <div className={styles.item}>Примерная численность сотрудников <span className={styles.bold}>{data.number_of_employees}</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalDlg show={showEditDlg} title='Редактирование данных компании' showButtons={false} onClose={() => setShowEditDlg(false)}>
                <Form formData={editData} structure={{
                    body: [
                        {
                            elements: [
                                {type: 'text', label: 'Название', isRequired: true, dataProperty: 'name', validate: validateRequired},
                                {type: 'flex', label: '', children: [
                                    {type: 'text', label: 'ИНН', isRequired: true, dataProperty: 'inn', validate: validateRequired},
                                    {type: 'text', label: 'КПП', isRequired: true, dataProperty: 'kpp', validate: validateRequired},
                                ]},
                                {type: 'select', label: 'Примерная численность сотрудников', isRequired: true, dataProperty: 'number_of_employees', validate: validateRequired, placeholder: 'Выберите...', selectOptions: [ { value: 10, label: '10' }, { value: 100, label: '100' }, { value: 500, label: '500' }, { value: 1000, label: '1000' } ]}
                            ]
                        }
                    ],
                    footer: { elements: [ { type: 'submit', label: 'Сохранить' }, { type: 'button', label: 'Отменить', onClick: () => setShowEditDlg(false) } ]}
                }} onSubmit={submitEditCompany} />
            </ModalDlg>
        </div>
    );
}
import { SurveyData } from "../types/survey";
import { UserSurveyData } from "../types/user";
import { fetchApi, handleHttpResponse, requestGet, requestPost } from "./api";

export const createSurvey = async (data: SurveyData) => {
    return await fetchApi('/v1/survey/create', requestPost(data)).then(handleHttpResponse);
}

export const listSurveys = async (offset: number, limit: number) => {
    return await fetchApi('/v1/survey/list', requestGet()).then(handleHttpResponse);
}

export const getSurvey = async (survey_id: string) => {
    return await fetchApi(`/v1/pub/survey/${survey_id}`, requestGet()).then(handleHttpResponse);
}

export const getSurveyAnswers = async (assessment_id: number, survey_id: string, user_id: string) => {
    return await fetchApi(`/v1/pub/user_survey/get_answers/${assessment_id}/${survey_id}/${user_id}`, requestGet()).then(handleHttpResponse);
}

export const postSurveyAnswer = async (assessment_id: number, survey_id: string, user_id: string, answer_id: number) => {
    return await fetchApi(`/v1/pub/user_survey/post_answer`, requestPost({ assessment_id: assessment_id, survey_id: survey_id, user_id: user_id, answer_id: answer_id })).then(handleHttpResponse);
}

export const commentAnswer = async (assessment_id: number, survey_id: string, user_id: string, answer_id: number, comment: string) => {
    return await fetchApi('/v1/pub/user_survey/comment_answer', requestPost({ assessment_id: assessment_id, survey_id: survey_id, user_id: user_id, answer_id: answer_id, comment: comment })).then(handleHttpResponse);
}

export const getScales = async (survey_id: string) => {
    return await fetchApi(`/v1/pub/survey/${survey_id}/scales`, requestGet()).then(handleHttpResponse);
}

